import {
  LenderIdentificationStatus,
  Money,
  Role,
  UserStatus
} from "@/utils/api-client";
import { Phone } from "@/utils/api-client/models/common/Phone";
import { Company } from "@/utils/api-client/models/company/Company";
import { Profile as ProfileInterface } from "@/utils/api-client/models/user/Profile";

export class Profile {
  private _profile?: ProfileInterface;
  private _balance?: Money;
  private _lenderHasIdentificationDraft?: boolean;

  constructor(profile?: ProfileInterface) {
    profile && this.update(profile);
  }

  get uuid(): string | undefined {
    return this._profile?.uuid;
  }

  get role(): Role | undefined {
    return this._profile?.role;
  }

  get status(): UserStatus | undefined {
    return this._profile?.status;
  }

  get email(): string | undefined {
    return this._profile?.email;
  }

  get name(): string | undefined {
    return this._profile?.name;
  }

  get balance(): Money | undefined {
    return this._balance;
  }

  get reservedFunds(): Money | undefined {
    return this._profile?.reservedFunds;
  }

  get paymentCode(): string | undefined {
    return this._profile?.paymentCode;
  }

  get phoneVerified(): boolean | undefined {
    return this._profile?.phoneVerified;
  }

  get hasProjectRequests(): boolean | undefined {
    return this._profile?.borrower?.hasProjectRequests;
  }

  get firstProjectRequestUuid(): string | undefined {
    return this._profile?.borrower?.firstProjectRequestUuid;
  }

  get lenderVerified(): boolean | undefined {
    return this._profile?.lender?.verified;
  }

  get lenderStatus(): LenderIdentificationStatus | undefined {
    return this._profile?.lender?.identificationStatus;
  }

  get identificationDate(): string | undefined {
    return this._profile?.lender?.identificationDate;
  }

  get lenderNotes(): string | undefined {
    return this._profile?.lender?.identificationNotes;
  }

  get phone(): Phone | undefined {
    return this._profile?.phone;
  }

  get investmentAllow(): boolean | undefined {
    return this._profile?.lender?.investmentAllow;
  }

  get maxInvestmentAllow(): boolean | undefined {
    return this._profile?.lender?.maxInvestmentAllow;
  }

  get investmentLimit(): Money | undefined {
    return this._profile?.lender?.investmentLimit;
  }

  get secondaryMarketV2Allow(): boolean | undefined {
    return this._profile?.lender?.secondaryMarketV2Allow;
  }

  get primaryMarketAllow(): boolean | undefined {
    return this._profile?.lender?.primaryMarketAllow;
  }

  get getPrimaryMarketAgreement(): Array<string> | undefined {
    return this._profile?.lender?.primaryMarketAgreement;
  }

  get getPrimaryAgreementSign(): string | undefined {
    return this._profile?.lender?.primaryAgreementSign;
  }

  get getQualifiedInvestorsDocuments(): Array<string> | undefined {
    return this._profile?.lender?.qualifiedInvestorDocuments;
  }

  get getAgreementExpiryDate(): string | undefined {
    return this._profile?.lender?.agreementExpiryDate;
  }

  get isBorrower(): boolean {
    return this._profile?.role === Role.BORROWER;
  }

  get isLender(): boolean {
    return this._profile?.role === Role.LENDER;
  }

  get isEntrepreneur(): boolean {
    return this._profile?.status === UserStatus.ENTREPRENEUR;
  }

  get isIndividual(): boolean {
    return this._profile?.status === UserStatus.INDIVIDUAL;
  }

  get isLegalEntity(): boolean {
    return this._profile?.status === UserStatus.LEGAL_ENTITY;
  }

  get lenderDataOnModeration(): boolean {
    return !!this._lenderHasIdentificationDraft && !this._profile?.lender?.identificationNotes;
  }

  get lenderInfoRequested(): boolean {
    return this._profile?.lender?.identificationStatus === LenderIdentificationStatus.INFO_REQUESTED &&
      !!this._profile?.lender?.identificationNotes;
  }

  get lenderUpdateRequested(): boolean {
    return this._profile?.lender?.identificationStatus === LenderIdentificationStatus.UPDATE_REQUESTED;
  }

  get company(): Company | undefined {
    return this._profile?.borrower?.company;
  }

  get isRulesConfirmed(): boolean {
    return this._profile?.personalInfo?.rulesConfirmed || false;
  }

  update(profile: ProfileInterface): Profile {
    this._profile = profile;
    this._lenderHasIdentificationDraft = profile.lender?.hasIdentificationDraft;
    return this.updateBalance(profile.balance);
  }

  updateBalance(balance: Money): Profile {
    this._balance = balance;
    return this;
  }

  markLenderDataOnModeration(): Profile {
    this._lenderHasIdentificationDraft = true;
    return this;
  }
}
