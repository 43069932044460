<template>
  <div class="dashboard">
    <common-header v-if="!profile.uuid || !routeAvailable"></common-header>
    <header-menu v-if="profile.uuid && routeAvailable"></header-menu>
    <div class="content position-relative">
      <data-loading :loading="dataLoading" :lock="true"/>
      <router-view v-if="profile.uuid && routeAvailable"
                   @dashboard-event="dashboardEvent"
      ></router-view>
      <page-not-found v-if="profile.uuid && !routeAvailable"></page-not-found>
    </div>
    <footer-menu></footer-menu>
  </div>
</template>

<script>
import { Unauthorized, ValidationError } from "@/utils/api-client";
import { createProfileManager } from "@/utils/di";
import { AuthTokenExpired } from "@/utils/errors";
import { CommonHeader, DataLoading, FooterMenu, PageNotFound } from "../common";
import HeaderMenu from "./components/header-menu/HeaderMenu";
import { DashboardEventType } from "./enums/DashboardEventType";
import { Profile } from "./models/Profile";
import {OutdatedPassword} from "@/utils/api-client/errors/OutdatedPassword";

export default {
  name: "Dashboard",
  components: {
    CommonHeader,
    DataLoading,
    FooterMenu,
    HeaderMenu,
    PageNotFound
  },
  data() {
    return {
      dataLoading: true,
      profile: new Profile()
    };
  },
  provide() {
    return {
      profile: this.profile
    };
  },
  async mounted() {
    try {
      await this.updateProfile();

      if (this.profile.isBorrower && this.profile.firstProjectRequestUuid) {
        await this.$router.replace({ name: "myProjectRequests.request.general", params: { uuid: this.profile.firstProjectRequestUuid } });
        return;
      }

      if (sessionStorage.getItem("loggedIn")) {
        sessionStorage.removeItem("loggedIn");
        if (this.profile.isLender && this.profile.lenderVerified === false) {
          await this.$router.replace({ name: "authorization" })
          return;
        }
      }

      if (this.$route.name === "dashboard") {
        await this.redirectToChild();
      }
    } finally {
      this.dataLoading = false;
    }

    this.$analytics.setUserId(this.profile.paymentCode);
  },
  computed: {
    routeAvailable() {
      return !this.$route.meta || !this.$route.meta.role || this.$route.meta.role === this.profile.role;
    }
  },
  methods: {
    async redirectToChild() {
      if (this.profile.isBorrower) {
        await this.$router.replace({ name: "myProjects" })
      } else if (this.profile.isLender) {
        await this.$router.replace({ name: "invest" })
      }
    },
    dashboardEvent(eventType) {
      switch (eventType) {
        case DashboardEventType.ProjectRequestCreated:
        case DashboardEventType.ProjectPublished:
        case DashboardEventType.Invested:
        case DashboardEventType.InvestmentCanceled:
        case DashboardEventType.Purchased:
          this.updateProfile();
          break;
        case DashboardEventType.WithdrawRequested:
        case DashboardEventType.WithdrawCanceled:
          this.updateBalance();
          break;
        case DashboardEventType.RulesConfirmed:
          this.updateProfile();
          break;
      }
    },
    async updateBalance() {
      const manager = await createProfileManager();
      this.profile.updateBalance(await manager.getBalance());
    },
    async updateProfile() {
      const manager = await createProfileManager();
      try {
        this.profile.update(await manager.get());
      } catch (error) {
        if (error instanceof OutdatedPassword) {
          this.$router.replace({ name: "outdated-password" });
        } else {
          throw error;
        }
      }
    }
  },
  watch: {
    "$route.name"(name) {
      if (name === "dashboard") {
        this.redirectToChild();
      }
    }
  },
  errorCaptured(err) {
    if (err instanceof AuthTokenExpired || err instanceof Unauthorized) {
      sessionStorage.setItem("redirectTo", this.$route.path);
      this.$router.replace({ name: "login" });
    } else if (err instanceof ValidationError) {
      this.$notifications.addAlert(err.message);
      console.error(err);
    } else {
      throw err;
    }
  }
};
</script>

<style lang="scss">
.dashboard {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .auth-header {
    flex: 0 0 auto;
  }
  .content {
    min-height: calc(100vh - 455px);
    flex: 1 0 auto;
  }
  .footer-menu {
    flex: 0 0 auto;
  }
}
</style>
