<template>
  <div class="borrower-main-menu">
    <nav class="navbar navbar-expand-lg navbar-light">
      <div class="container-xl">
        <public-link class="navbar-brand mb-auto" to="/">
          <img alt="Lendly" src="@/assets/logo-fixed.svg" class="d-none d-md-inline-block">
          <img alt="Lendly" src="@/assets/landing-black-logo.png" class="d-md-none">
        </public-link>
        <div class="ml-auto account-info-mobile d-lg-none">
          <span class="navbar-text mr-3 account-info-name">
            <i class="bi bi-person-fill d-none d-md-inline"></i>
            {{ profile.name }}
          </span>
        </div>
        <button class="navbar-toggler mb-auto"
                type="button"
                data-toggle="collapse"
                data-target="#top-menu"
                aria-controls="top-menu"
                aria-expanded="false"
                aria-label="Toggle navigation"
                ref="topMenuToggler"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="top-menu" @click="mobileMenuSelected">
          <ul class="navbar-nav d-lg-none">
            <li class="nav-item" :class="{ active: isActive('myProjects') }">
              <router-link class="nav-link" :to="{ name: 'myProjects' }">Мои проекты</router-link>
            </li>
            <li class="nav-item" :class="{ active: isActive('myProjectRequests') }">
              <router-link class="nav-link" :to="{ name: 'myProjectRequests' }">Мои заявки</router-link>
            </li>
            <li class="nav-item" :class="{ active: isActive('transactions') }">
              <router-link class="nav-link" :to="{ name: 'transactions' }">Транзакции</router-link>
            </li>
          </ul>
          <ul class="navbar-nav d-lg-none">
            <li class="nav-item">
              <router-link v-if="!profile.hasProjectRequests" class="nav-link" :to="{ name: 'myProjectRequests.create' }">Новая заявка</router-link>
              <router-link v-if="profile.hasProjectRequests" class="nav-link" :to="{ name: 'myProjectRequests' }">Статус заявки</router-link>
            </li>
          </ul>
          <ul class="navbar-nav d-lg-none">
            <li class="nav-item" :class="{ active: isActive('profile') }">
              <router-link :to="{ name: 'profile' }" class="nav-link">Профиль</router-link>
            </li>
            <li class="nav-item" :class="{ active: isActive('notifications') }">
              <router-link :to="{ name: 'notifications' }" class="nav-link">Уведомления</router-link>
            </li>
          </ul>
          <ul class="navbar-nav ml-md-auto">
<!--            <li class="nav-item">-->
<!--              <public-link class="nav-link" to="/page/how-it-work">-->
<!--                Как это работает-->
<!--              </public-link>-->
<!--            </li>-->
            <li class="nav-item">
              <public-link class="nav-link" to="/faq">
                FAQ
              </public-link>
            </li>
          </ul>
          <ul class="navbar-nav d-lg-none">
            <li class="nav-item">
              <router-link class="nav-link" :to="{ name: 'logout' }">Выход</router-link>
            </li>
          </ul>
          <ul class="navbar-nav d-none d-lg-block">
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle"
                 href="#"
                 id="profile"
                 role="button"
                 data-toggle="dropdown"
                 aria-haspopup="true"
                 aria-expanded="false"
              >
                <i class="bi bi-person-fill"></i>
                {{ profile.name }}
              </a>
              <div class="dropdown-menu" aria-labelledby="profile">
                <router-link :to="{ name: 'profile' }" class="dropdown-item">Профиль</router-link>
                <router-link :to="{ name: 'notifications' }" class="dropdown-item">Уведомления</router-link>
                <div class="dropdown-divider"></div>
                <router-link class="dropdown-item" :to="{ name: 'logout' }">Выход</router-link>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <nav class="navbar navbar-expand-lg navbar-light d-none d-lg-flex block-main-menu">
      <div class="container-xl" id="main-menu">
        <ul class="navbar-nav">
          <li class="nav-item" :class="{ active: isActive('myProjects') }">
            <router-link class="nav-link" :to="{ name: 'myProjects' }">Мои проекты</router-link>
          </li>
          <li class="nav-item" :class="{ active: isActive('myProjectRequests') }">
            <router-link class="nav-link" :to="{ name: 'myProjectRequests' }">Мои заявки</router-link>
          </li>
          <li class="nav-item" :class="{ active: isActive('transactions') }">
            <router-link class="nav-link" :to="{ name: 'transactions' }">Транзакции</router-link>
          </li>
        </ul>
        <div class="navbar-nav ml-auto">
          <router-link v-if="!profile.hasProjectRequests"
                       :to="{ name: 'myProjectRequests.create' }"
                       class="btn btn-sm btn-success my-auto px-5"
          >Новая заявка</router-link>
          <router-link v-if="profile.hasProjectRequests"
                       :to="{ name: 'myProjectRequests' }"
                       class="btn btn-sm btn-info my-auto px-5"
          >Статус заявки</router-link>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { Money, PublicLink } from "@/components/common";

export default {
  name: "BorrowerMainMenu",
  components: {
    PublicLink,
    Money
  },
  inject: ["profile"],
  methods: {
    isActive(routeName) {
      const route = this.$router.resolve({ name: routeName });
      return this.$route.matched.some((item) => item.name === route.name || item.path.indexOf(route.path + '/') > -1);
    },
    mobileMenuSelected(e) {
      const topMenuToggler = this.$refs.topMenuToggler;
      if (e.target.className.indexOf("nav-link") > -1 && topMenuToggler && topMenuToggler.getAttribute("aria-expanded") === "true") {
        topMenuToggler.click();
        window.scrollTo(0, 0);
      }
    }
  }
};
</script>

<style lang="scss">
.borrower-main-menu {
  .navbar-expand-lg .navbar-nav .dropdown-menu  {
    left: auto;
    right: 0;
  }

  .navbar {
    padding: 0.5rem;
  }

  .navbar .account-info-mobile {
    max-width: calc(100% - 150px);
  }

  .navbar .account-info-mobile .account-info-name {
    color: #000;
  }

  .navbar.block-main-menu {
    margin-top: 0.125rem;
  }

  .navbar #top-menu .navbar-nav {
    border-top: 1px solid #e9ecef;
    margin-top: 1rem;
    padding-top: 1rem;
  }

  .navbar #top-menu .navbar-nav .nav-item.active:before {
    background: none;
  }

  .navbar #top-menu .navbar-text .money,
  .navbar .account-info-mobile .money {
    color: #000;
    margin-left: 0.25rem;
  }

  .navbar #top-menu .navbar-nav .nav-item,
  .navbar #top-menu .navbar-text,
  .navbar #main-menu .navbar-nav .nav-item {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }

  .navbar #main-menu .navbar-nav .nav-item:first-child {
    margin-left: 0;
  }

  .navbar #main-menu .navbar-nav .nav-item:last-child {
    margin-right: 0;
  }

  @media (min-width: 992px) { // Bootstrap LG
    .navbar #top-menu .navbar-nav {
      border-top: none;
      margin-top: 0;
      padding-top: 0;
    }

    .navbar #top-menu .navbar-nav .nav-item.active:before {
      background: #21e58a;
    }
  }

  @media (min-width: 1040px) {
    .navbar #top-menu .navbar-nav .nav-item,
    .navbar #top-menu .navbar-text,
    .navbar #main-menu .navbar-nav .nav-item {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }

    .navbar #top-menu .navbar-text .money {
      margin-left: 0.5rem;
    }
  }

  @media (min-width: 1140px) {
    .navbar #top-menu .navbar-nav .nav-item,
    .navbar #top-menu .navbar-text,
    .navbar #main-menu .navbar-nav .nav-item {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }
}
</style>
