<template>
  <div class="header">
    <borrower-main-menu v-if="profile.isBorrower"></borrower-main-menu>
    <lender-main-menu v-if="profile.isLender"></lender-main-menu>
    <div v-if="!(profile.isRulesConfirmed) && displayRuleConfirmation" class="container-xl">
      <div class="card border-info mb-0">
        <div class="card-body">
          <div class="h6 m-0 p-0 text-info">
            Пожалуйста
            <router-link :to="{ name: 'rules-confirmation' }" class="text-nowrap">ознакомьтесь с новыми правилами
              платформы
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div v-if="displayLenderInfo" class="container-xl">
      <div class="card border-info mb-0">
        <div class="card-body">
          <div v-if="profile.lenderInfoRequested" class="h6 m-0 p-0 text-info">
            Необходимо
            <router-link :to="{ name: 'authorization' }" class="text-nowrap">указать дополнительную информацию
            </router-link>
          </div>
          <div v-else-if="profile.lenderUpdateRequested" class="h6 m-0 p-0 text-info">
            Необходимо
            <router-link :to="{ name: 'authorization' }" class="text-nowrap">обновить данные авторизации</router-link>
          </div>
          <div v-else-if="profile.lenderVerified === false" class="h6 m-0 p-0 text-info">
            Необходимо
            <router-link :to="{ name: 'authorization' }" class="text-nowrap">пройти авторизацию</router-link>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!telegramWatched && displayTelegramSubscribe" class="container-xl">
      <div class="card border-info mb-0">
        <div class="card-body d-flex align-items-center justify-content-between ">
          <div class="telegram_text h6 m-0 p-0 text-info d-flex align-items-center justify-content-between">
            <img class="telegram_icon" src="@/assets/img/telegram.svg" alt="Telegram icon">
            <span>
            Подпишись,
            <a href="https://t.me/lendly" class="text-nowrap">
              будь в курсе новых инвестиций
            </a>
              </span>
          </div>

          <button @click="closeTelegram" type="button" class="btn btn-outline-danger">
            Закрыть
          </button>
        </div>
      </div>
    </div>
    <div v-if="!supportWatched && displaySupportMail && !isQualified" class="container-xl">
      <div class="card border-info mb-0">
        <div class="card-body d-flex align-items-center justify-content-between ">
          <div class="telegram_text h6 m-0 p-0 text-info d-flex align-items-center justify-content-between">
            <span v-if="this.profile.uuid === '5786ee01-92e7-41b0-afdc-588fe2c3fa41'">
              Уведомляем Вас о том, что Ваш статус квалифицированного инвестора аннулирован 11.07.2024 г.
            </span>
            <span v-else>
            Чтобы получить статус квалифицированного инвестора, обратитесь в
            <a href="mailto:support@lendly.ru" class="text-nowrap">
              поддержку
            </a>
              </span>
          </div>

          <button @click="closeSupport" type="button" class="btn btn-outline-danger">
            Закрыть
          </button>
        </div>
      </div>
    </div>
    <div v-if="!agreementWatched && displayAgreementWarning && isQualified && DaysUntilAgreementExpire !== null">
      <div v-if="DaysUntilAgreementExpire <= 14 && DaysUntilAgreementExpire > 0"
           class="container-xl">
        <div class="card border-warning mb-0">
          <div class="card-body d-flex align-items-center justify-content-between ">
            <div class="telegram_text h6 m-0 p-0 d-flex align-items-center justify-content-between">
              <img class="telegram_icon" src="@/assets/img/warning.svg" alt="Warning icon">
              <span>
            Срок действия вашей доверенности закончится через
               <span class="text-danger text-nowrap font-weight-bold" href="mailto:support@lendly.ru">
              {{ DaysUntilAgreementExpire }} {{ countNoun(DaysUntilAgreementExpire, 'день', 'дня', 'дней') }}
            </span>
              </span>
            </div>

            <button @click="closeAgreementWarning" type="button" class="btn btn-outline-danger">
              Закрыть
            </button>
          </div>
        </div>
      </div>
      <div v-else-if="DaysUntilAgreementExpire <= 0" class="container-xl">
        <div class="card border-danger mb-0">
          <div class="card-body d-flex align-items-center justify-content-between ">
            <div class="telegram_text h6 m-0 p-0 d-flex align-items-center justify-content-between">
              <img class="telegram_icon" src="@/assets/img/warning.svg" alt="Warning icon">
              <span>
              Срок действия вашей доверенности закончился!
            </span>
            </div>
            <button @click="closeAgreementWarning" type="button" class="btn btn-outline-danger">
              Закрыть
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import BorrowerMainMenu from "./components/BorrowerMainMenu";
import LenderMainMenu from "./components/LenderMainMenu";
import {appConfig} from "@/configs";
import {countNoun} from "@/utils/api-client/utils/countNoun";

export default {
  name: "HeaderMenu",
  components: {
    BorrowerMainMenu,
    LenderMainMenu
  },
  data() {
    return {
      telegramWatched: false,
      supportWatched: false,
      agreementWatched: false
    };
  },
  inject: ["profile"],
  computed: {
    displayLenderInfo() {
      return this.profile.isLender &&
          this.$route.name !== "authorization" &&
          (this.profile.lenderInfoRequested || this.profile.lenderUpdateRequested || this.profile.lenderVerified === false);
    },
    displayRuleConfirmation() {
      return this.$route.name !== "rules-confirmation"
    },
    displayTelegramSubscribe() {
      return sessionStorage.getItem("telegramSubscribe") !== "watched"
    },
    displaySupportMail() {
      return sessionStorage.getItem("supportMail") !== "watched"
    },
    displayAgreementWarning() {
      return sessionStorage.getItem("agreementWarning") !== "watched"
    },
    isQualified() {
      return (this.profile.investmentLimit !== undefined && this.profile.investmentLimit.amount / 100 > appConfig.maxInvestment)
          || this.profile.investmentLimit === undefined;
    },
    DaysUntilAgreementExpire() {
      if (this.profile.getAgreementExpiryDate == null) {
        return null;
      }
      const currentDate = new Date();
      const expiryDate = new Date(this.profile.getAgreementExpiryDate);
      return Math.floor((expiryDate - currentDate) / (1000 * 60 * 60 * 24)) + 1;
    }
  },

  methods: {
    countNoun,
    closeTelegram() {
      sessionStorage.setItem("telegramSubscribe", "watched");
      this.telegramWatched = true;
    },
    closeSupport() {
      sessionStorage.setItem("supportMail", "watched");
      this.supportWatched = true;
    },
    closeAgreementWarning() {
      sessionStorage.setItem("agreementWarning", "watched");
      this.agreementWatched = true;
    },
  }
}
</script>
<style lang="scss">
.telegram_icon {
  max-width: 1.7rem;
}

.telegram_text {
  gap: 0.3rem;
}
</style>
